import * as React from 'react';

import {
  Route,
  useRouteMatch,
  Switch,
} from "react-router-dom";

import CustomSampleScreen from './CustomSampleScreen';


/**
 * Custom router to route to your custom screen
 */
function CustomRouter() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/sample-screen`} component={CustomSampleScreen} />
    </Switch>
  );
}

export default CustomRouter;