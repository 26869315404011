import * as React from 'react';
import './Rcptf031Snapshot.css'
import { TerminalCommunicationService, model } from "@fresche/terminal-lib";
import { getSessionId, getAuthType, getTenantId } from '../security/AuthProxy'

declare global {
  interface Window { config: any; }
}

export interface SnapshotProps {
   params:any;
}

class Rcdc3001Snapshot<P = {}, S = any> extends React.Component<SnapshotProps, any> {
   backendUrl: string;
  formData;
  state = {
    drugCstDetails:{
    schedule: "",
    description: "",
    status: "",
    criteria:[],
  },
    hideCriteria: true, 
     
  };

  hideLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.remove('show');
    }
  }
  componentDidMount(): void {
    this.getData();
  }

  /**
   * Calls backend to get data to populate table
   */
  getData() {
   
    this.formData = this.props.params;
    const snapshot = this.formData;
    console.log(this.formData);
    const priceSchdSnapshotDtls = {
      schedule: snapshot.fields["ctl.dc3DrugCostSchedName"],
      description: snapshot.fields["ctl.dc3DrugCostSchedDesc"],
      status: snapshot.fields["ctl.hiddenRecordIndicator"],
      criteria: snapshot["subfile"]
    };

    this.setState({ drugCstDetails: priceSchdSnapshotDtls });
  }

  toggleExpandable(obj) {
    this.setState(obj);
  }

  /**
   * returns params to route between screens
   * @param programName 
   */
  getParams(programName: string) {
    if(programName == "RCDC4002") {
       const params = [this.props.params[1],this.props.params[0],true]
       console.log(params);
      return params;
    }
  }

  /**
   * redirect to screens from snapshot
   * @param programName 
   */
  redirect(programName: string) {
    window.parent.postMessage(
    {
      message: "navigate-to-program-internal",
      programCode: programName,
      parameters : this.getParams(programName)
    }, '*')
  }
  updateHideCriteria(b){
    this.setState(prevState =>
      ({ ...prevState, hideCriteria: b  }))
  }
  getCriteria(){
    if(this.state.hideCriteria){
      return (
        <div>
            <table>
              <tr>
                <td onClick={() => this.toggleExpandable({hideCriteria:false})}>►</td>
                <td><a onClick = {()=> {this.replyToBackend(this.formData,'dtl.criteria')}}><h5>Criteria</h5></a></td>
              </tr>
            </table>
        </div>
      )}else{
        const list = this.state.drugCstDetails.criteria;
    let items = [];
    if(list != undefined) {
      console.log(list);
      items = list.map(ds => {return(       
          <tr key={ds.fields["16"]}>
          <td>{ds.fields["16"]}</td>
          <td>{ds.fields["15"]}</td>
          <td>{ds.fields["5"]}</td>
          <td>{ds.fields["6"]}</td>
          <td>{ds.fields["7"]}</td>
          <td>{ds.fields["8"]}</td>
          <td>{ds.fields["9"]}</td>
          <td>{ds.fields["10"]}</td>
          <td>{ds.fields["11"]}</td>
          <td>{ds.fields["12"]}</td>
          <td>{ds.fields["13"]}</td>
          <td>{ds.fields["14"]}</td>
          <td>{ds.fields["1"]}</td>
          <td>{ds.fields["0"]}</td>
          <td>{ds.fields["dc4AwpComparison"]}</td>
        </tr>
      )
      }
      )
    }
        if(items.length == 0){
          items.push(<div className="text">No Data Found</div>)
          return (
            <div>
              <table>
                <tr>
                <td onClick={() => this.toggleExpandable({ hideCriteria: true })}>▼</td>                
                <td><a onClick = {()=> {this.replyToBackend(this.formData,'dtl.criteria')}}><h5>Criteria</h5></a></td>
                </tr>
                </table>
              {items}
              </div>
          )
        } else {
          return (
            <div>
              <table>
                <tr>
                <td onClick={() => this.toggleExpandable({ hideCriteria: true })}>▼</td>
                <td><a onClick = {()=> {this.replyToBackend(this.formData,'dtl.criteria')}}><h5>Criteria</h5></a></td>
                </tr>
                </table>
                <table className="display">
          <tbody>
            <tr>
              <td>
                <strong>Seq</strong>
              </td>
              <td>
                <strong>PSC A</strong>
              </td>
              <td>
                <strong>PSC 0</strong>
              </td>
              <td>
                <strong>PSC 1</strong>
              </td>
              <td>
                <strong>PSC 2</strong>
              </td>
              <td>
                <strong>PSC 3</strong>
              </td>
              <td>
                <strong>PSC 4</strong>
              </td>
              <td>
                <strong>PSC 5</strong>
              </td>
              <td>
                <strong>PSC 6</strong>
              </td>
              <td>
                <strong>PSC 7</strong>
              </td>
              <td>
                <strong>PSC 8</strong>
              </td>
              <td>
                <strong>PSC 9</strong>
              </td>
              <td>
                <strong>Comparison Schedule</strong>
              </td>
              <td>
                <strong>Comparison Type</strong>
              </td>
              <td>
                <strong>AWP Comparison</strong>
              </td>
            </tr>
            {items}
          </tbody>
        </table>
            </div>
          )
          }
        }    
      }

    replyToBackend = (newFormData: any, field: any ) => {
    const terminal = new TerminalCommunicationService();
    newFormData['cmdKey'] = '04';
    newFormData['cursorField'] = field;
    model.next({
      data: newFormData
    });
    terminal.reply();
  }
  getPriceScheduleSnapshot(): React.ReactElement {
    return (
      <React.Fragment>
        <hr></hr>
        
        <div className="p-grid">
          <div className="p-col">
            <table>
              <tr>
                <td><strong>Schedule: </strong></td>
                <td></td>
                <td></td>
                <td>{this.state.drugCstDetails.schedule}</td>
              </tr>
            </table>
            
          </div>
          <div className="p-col">
            <table>
              <tr>
                <td><strong>Description: </strong></td>
                <td></td>
                <td></td>
                <td>{this.state.drugCstDetails.description}</td>
              </tr>
            </table>
          </div>
          <div className="p-col">
            <table>
              <tr>
                <td><strong>Status: </strong></td>
                <td></td>
                <td></td>
                <td>{this.state.drugCstDetails.status}</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col"> 
            <div>
              {this.getCriteria()}
            </div>            
          </div>
        </div>
      </React.Fragment >
    )
  }

  /**
   *
   */
  render(): React.ReactElement {  
      return this.getPriceScheduleSnapshot();   
  }
}

export default Rcdc3001Snapshot;
