import * as React from 'react';
import './Rcptf031Snapshot.css'
import { TerminalCommunicationService, model } from "@fresche/terminal-lib";

declare global {
  interface Window { config: any; }
}

export interface SnapshotProps {
  params: any;
}

class Rcprc003Snapshot<P = {}, S = any> extends React.Component<SnapshotProps, any> {
  backendUrl: string;
  formData;

  state = {
    priceSchedule: {
      prcPriceScheduleName: '',
      description: '',
      status:'',
      plans:[],
      clientLimit: {
        rplMaximumLimitPct : 0,
        rplMaximumBasis : '',
        rplIncreaseRblPriceQuestion : ''
      },
      clientPrcschdls: [],
      criteria: []
    },
    hidePlans: true,
    hideClientLimit: true,
    hideClientPrcSchd: true,
    hideCriteria: true,

  };

  constructor(props: SnapshotProps) {
    super(props);
  }

  showLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.add('show');
    }
  }

  hideLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.remove('show');
    }
  }

  componentDidMount(): void {
    this.getData();
  }

  /**
   * Calls backend to get data to populate table
   */
  getData() {
    this.formData = this.props.params;
    const snapshot = this.formData.fields;
    const priceSchdSnapshotDtls = {
      prcPriceScheduleName: snapshot["dtl.prcPriceScheduleName"],
      description: snapshot["dtl.description"],
      status: snapshot["dtl.status"],
      plans: snapshot["dtl.plans"],
      clientLimit: snapshot["dtl.clientLimit"],
      clientPrcschdls: snapshot["dtl.clientPrcschdls"],
      criteria: snapshot["dtl.criteria"]
    };
    this.setState({ priceSchedule: priceSchdSnapshotDtls });
  }

  toggleExpandable(obj) {
    this.setState(obj);
  }


  getPlans() {
    if (this.state.hidePlans) {
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hidePlans: false })}>►</td>
              <td> 
              <a onClick = {()=> {this.replyToBackend(this.formData,'dtl.plans')}}>
                                                <h5>Plans</h5></a></td>
            </tr>
          </table>
        </div>
      )
    } else {
    const list = this.state.priceSchedule.plans;
    let items = [];
    if(list != undefined) {
      items = list.map(ds =>
        <tr key={ds}>
          <td>{ds}</td>
        </tr>
      )
    }
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hidePlans: true })}>▼</td>
              <td> 
              <a onClick = {()=> {this.replyToBackend(this.formData,'dtl.plans')}}>
                                                <h5>Plans</h5></a></td>
            </tr>
          </table>
          <table className="display">
            <tbody>
              <tr>
                <td>Plan codes</td>
              </tr>
                {items}
            </tbody>
          </table>
        </div>
      ) 
    }

  }

  getClientLimit() {

    if (this.state.hideClientLimit) {
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideClientLimit: false })}>►</td>
              <td> 
              <a onClick = {()=> {this.replyToBackend(this.formData,'dtl.clientLimit')}}>
                                                <h5>Client Limits</h5></a></td>
            </tr>
          </table>
        </div>
      )
    } else {
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideClientLimit: true })}>▼</td>
              <td><a onClick = {()=> {this.replyToBackend(this.formData,'dtl.clientLimit')}}>
                                                <h5>Client Limits</h5></a></td>
            </tr>
          </table>

          <table className='display'>
            <tbody>
              <tr>
                <td>
                Decrease Client Price
                </td>
              </tr>
              <tr>
                <td>
                Maximum Limit %:
                </td>
                <td>{this.state.priceSchedule.clientLimit.rplMaximumLimitPct}</td>
              </tr>
              <tr>
                <td>
                Maximum Basis:
                </td>
                <td>{this.state.priceSchedule.clientLimit.rplMaximumBasis}</td>
              </tr>
              <tr>
                <td>
                Increase Client Price to Pharmacy Price:
                </td>
                <td>{this.state.priceSchedule.clientLimit.rplIncreaseRblPriceQuestion}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  }

  getClientPrcSchd() {

    if (this.state.hideClientPrcSchd) {
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideClientPrcSchd: false })}>►</td>
              <td><a onClick = {()=> {this.replyToBackend(this.formData,'dtl.clientPriceSchd')}}>
                                                <h5>Client price schedule</h5></a></td>
            </tr>
          </table>
        </div>
      )
    } else {
      const list = this.state.priceSchedule.clientPrcschdls;
      let items = [];
      if(list != undefined) {
        items = list.map(ds =>
          <tr key={ds}>
            <td>{ds}</td>
          </tr>
        )
      }
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideClientPrcSchd: true })}>▼</td>
              <td><a onClick = {()=> {this.replyToBackend(this.formData,'dtl.clientPriceSchd')}}>
                                                <h5>Client price schedule</h5></a></td>
            </tr>
          </table>
          <table className='display'>
            <tbody>
              <tr>
                <td>
                Client price schedule id
                </td>
              </tr>
                {items}
            </tbody>
          </table>
        </div>
      )
    }
  }

  getCriteria() {
    if (this.state.hideCriteria) {
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideCriteria: false })}>►</td>
              <td><a onClick = {()=> {this.replyToBackend(this.formData,'dtl.criteria')}}>
                                                <h5>Criteria</h5></a></td>
            </tr>
          </table>
        </div>
      )
    } else {
      const list = this.state.priceSchedule.criteria;
      let items = [];
      if(list != undefined) {
     
        items = list.map(ds =>
          <tr key={ds.pscSeqNbr}>
            <td>{ds.pscSeqNbr}</td>
            <td>{ds.pscPriceTable}</td>
          </tr>
        )
      }

      if(items.length == 0){
        items.push(<div className="text">No Data Found</div>)
        return (
          <div>
            <table>
              <tr>
              <td onClick={() => this.toggleExpandable({ hideCriteria: true })}>▼</td>
              <td><a onClick = {()=> {this.replyToBackend(this.formData,'dtl.criteria')}}>
                                                <h5>Criteria</h5></a></td>
              </tr>
            </table>
            {items}
          </div>
        )
      } else {
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideCriteria: true })}>▼</td>
              <td><a onClick = {()=> {this.replyToBackend(this.formData,'dtl.criteria')}}>
                                                <h5>Criteria</h5></a></td>
            </tr>
          </table>
          <table className='display'>
            <tbody>
              <tr>
                <td>
                Sequence 
                </td>
                <td>
                Price Table ID 
                </td>
              </tr>
               {items}
            </tbody>
          </table>
        </div>
      )
    }
    }
  }

  replyToBackend = (newFormData: any, field: any ) => {
    const terminal = new TerminalCommunicationService();
    newFormData['cmdKey'] = '04';
    newFormData['cursorField'] = field;
    model.next({
      data: newFormData
    });
    terminal.reply();
  }

  getPriceScheduleSnapshot(): React.ReactElement {
    const { priceSchedule } = this.state;

    return (
      <React.Fragment>
        <hr></hr>

        <div className="p-grid">
          <div className="p-col-2">
            <table>
              <tbody>
                <tr>
                  <td><strong>Schedule: </strong></td>
                  <td></td>
                  <td>{priceSchedule.prcPriceScheduleName}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-col-4">
            <table>
              <tbody>
                <tr>
                  <td><strong>Description: </strong></td>
                  <td></td>
                  <td>{priceSchedule.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-col">
            <table>
              <tbody>
                <tr>
                  <td><strong>Status: </strong></td>
                  <td></td>
                  <td>{priceSchedule.status}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col">
            <div>
              {this.getPlans()}
              {this.getClientPrcSchd()}
            </div>
          </div>
          <div className="p-col">
            {this.getClientLimit()}            
            {this.getCriteria()}
          </div>
        </div>
      </React.Fragment >
    )
  }

  /**
   *
   */
  render(): React.ReactElement {
    return this.getPriceScheduleSnapshot();
  }
}

export default Rcprc003Snapshot;