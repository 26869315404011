import React from "react";
import Rcdc3001Snapshot from "./Rcdc3001Snapshot";
import Rcptf031Snapshot from "./Rcptf031Snapshot";
import Rcprc003Snapshot from "./Rcprc003Snapshot";
import Rcdc5001Snapshot from "./Rcdc5001Snapshot";

export default class SnapshotBase<P = {}, S = any> extends React.Component<any, any> {
    private formData: any;
    private programName: any;
    constructor(props) {
        super(props);
        this.formData = props.formData;
        this.programName = props.programName;
    }
    
    render() {
        const { modalScreen } = this.formData;
        return (
            <>

                {modalScreen && this.programName == "RCDC3001" && <Rcdc3001Snapshot params={this.formData}></Rcdc3001Snapshot>} 
                {modalScreen && this.programName == "RCPTF031" && <Rcptf031Snapshot params={this.formData}></Rcptf031Snapshot>} 
                {modalScreen && this.programName == "RCPRC003" && <Rcprc003Snapshot params={this.formData}></Rcprc003Snapshot>} 
                {modalScreen && this.programName == "RCDC5001" && <Rcdc5001Snapshot params={this.formData}></Rcdc5001Snapshot>} 

            </>
        )
    }
}