import * as React from 'react';

import { TerminalCommunicationService } from '@fresche/terminal-lib';
import { FGrid } from '@fresche/ui-lib-react';
import { MegaMenu } from 'primereact/megamenu';
import './custom-base.css';

declare global {
  interface Window { config: any; }
}

abstract class CustomBase<P = {}, S = any> extends React.Component<any, any> {
  protected terminal = new TerminalCommunicationService();

  public inputErrors: any;

  public dateFormat: string;
  public timeSeparator: string;
  public showSeconds = true;
  public funKeyPos: string;

  public conditions: any = {};

  public functionKeys: any;
  public formatedInputs: any[];
  public selectedActionField = 'selected';

  protected focusedField = '';
  protected focusedFieldId = '';
  protected tablerowFocusedField = -1; // 0 = input, >0 = datarow

  protected dataGrid: React.RefObject<FGrid> = React.createRef();
  public keysNavigation = React.createRef();
  protected screenDef: any;
  protected formData: any;

  protected menu: MegaMenu;

  componentDidUpdate(): void {
    if (this.screenDef.onLoad && (this as any)[this.screenDef.onLoad]) {
      (this as any)[this.screenDef.onLoad]();
    }
  }

  componentDidMount(): void {
    if (this.screenDef.onLoad && (this as any)[this.screenDef.onLoad]) {
      (this as any)[this.screenDef.onLoad]();
    }
  }

  /**
   *
   */
  public renderCustomComponent(input: any): React.ReactElement {
    switch (input.type) {
      case 'yourCustomType':
        return (<div>{input.label}</div>);

      case 'checkbox':
        return (<div>{input.label}</div>);

      /*
      case 'yourAnotherCustomInput':
        return (<yourAnotherCustomInput yourCustomPropertyLabel={input.label}></yourAnotherCustomInput>);
      */
    }
    return (<React.Fragment></React.Fragment>);
  }

  serviceNowDetector(): void {
    const regexp = /[TASK][A-Z]+[0-9]+/gi;
    if (this.props.formData['subfile'] && this.props.formData['subfile'].length > 0) {
      const noteTextList = document.getElementsByClassName('ServiceNowLink');
      for (let i = 0; i < noteTextList.length; i++) {
        const childElement = noteTextList[i].getElementsByClassName('fp-inputtext');
        for (let j = 0; j < childElement.length; j++) {
          const className = childElement[j].getAttribute('class');
          // avoid formating on add
          if (className != null && className.includes('p-disabled')) {
            const childElement2 = childElement[j];
            let trValue = childElement2.getAttribute('value');
            if (trValue != null) {
              const found = trValue.match(regexp);
              if (found !== null) {
                const hrefLink = '<a href="http://www.dell.com" target="_blank" rel="noopener noreferrer">';
                for (let k = 0; k < found.length; k++) {
                  trValue = trValue.replace(found[k], hrefLink + found[k] + '</a>');
                }
                const fText = noteTextList[i].getElementsByClassName('f-text');
                if (fText.length > 0) {
                  fText[0].innerHTML = '<link-text class="content-input-link-text">' + trValue + '</link-text>';
                }
              }
            }
          }
        }
      }
    }
  }
}

export default CustomBase;
