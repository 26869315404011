import * as React from 'react';
import Base from '../../base/base';
import { MegaMenu } from 'primereact/megamenu';
import { Tooltip } from 'primereact/tooltip';
import { ToastContainer } from 'react-toastify';
import { FContinueButton, FGrid } from '@fresche/ui-lib-react';

export default class CustomBase extends Base {
    
    // Overwrite the render() method so we can allow custom user Components to draw
    // their own full output
    render(): React.ReactElement {

        // Populate the properties of our known child element types with their expected values. This is 
        // required as most of these properties are only available in Base, but the user Component is
        // an extension of BaseView, so doesn't have access to these directly.
        const children = React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {

                // Currently Grid is the only element that has been implemented with extensive property
                // support. We create a clone of the child, setting all of the properties required
                // by FGrid.
                if (child.type === FGrid) {
                    return React.cloneElement(child, {
                        ref: (el: any): any => this.dataGrid = el,
                        onModelChange: (    newModel: any): any => {
                            if (child.props.onModelChange) {
                                child.props.onModelChange();
                            }
                            this.formData = newModel;
                            this.forceUpdate();
                        },
                        onPageChange: (event: any): void => {
                            if (child.props.onPageChange) {
                                child.props.onPageChange();
                            }
                            this.pageChanged({'direction' : event});
                        },
                        conditions: child.props.conditions ? child.props.conditions : this.conditions,
                        onActionButton: child.props.onActionButton ? child.props.onActionButton : this.tableAction,
                        dateFormat: child.props.dateFormat ? child.props.dateFormat : this.dateFormat,
                        timeSeparator: child.props.timeSeparator ? child.props.timeSeparator : this.timeSeparator,
                        showSeconds: child.props.showSeconds ? child.props.showSeconds : this.showSeconds,
                        onTableFieldFocus: (fieldName: string, shortFieldName: string, rowIndex: number): void => {
                            if (child.props.onTableFieldFocus) {
                                child.props.onTableFieldFocus(fieldName, shortFieldName, rowIndex);
                            }
                            this.onTableFieldFocus(fieldName, shortFieldName, rowIndex)
                        }
                    });
                }
            }
            return child;
        });

        // We output the common shared components (Error container, and function keys), then
        // output our modified child elements.
        return (
            <React.Fragment>
                {/* <!-- Toast container used for showing errors --> */}
                <ToastContainer
                    position="top-right"
                    autoClose={50000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnHover={true}
                />
                <div className="content-container clearfix">
                    {/* <!-- Function key toolbar --> */}
                    <div id="toolbar" className={this.getToolbarClasses()}>
                        <div className="function-keys">
                            {this.functionKeys && (
                                <MegaMenu ref={(el: any): any => 
                                this.menu = el} 
                                model={this.functionKeys} 
                                />
                            )}
                        </div>
                        <div className="right-buttons" style={{display: this.screenDef.hasContinueButton === true ? 'block' : 'none'}}>
                            <FContinueButton
                                id="continue-button"
                                title="Next →"
                                handleContinue={(): void => this.onSubmit()}
                                disabled={!this.props.isActiveScreen}
                            />
                        </div >
                    </div>
                    
                    {/* <!-- Modified child elements --> */}
                    {children}
                </div>
            </React.Fragment>
        );
    }
}