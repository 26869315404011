import * as React from 'react';
import './Rcptf031Snapshot.css'
import OptumTerminalCommunicationService from '../util/OptumTerminalCommunicationService';

declare global {
  interface Window { config: any; }
}

export interface SnapshotProps {
  params: any;
}

class Rcptf031Snapshot<P = {}, S = any> extends React.Component<SnapshotProps, any> {
  backendUrl: string;
  terminal: OptumTerminalCommunicationService;
  formData;

  state = {
    priceTable: {
      ptfPricingTableName: '',
      ptfDescription: '',
      ptfFromDate: '',
      ptfThruDate: '',
      ptfStatus: '',
      ptfCostSource1: '',
      ptfCostSource2: '',
      ptfCostSource3: '',
      ptfCostType1: '',
      ptfCostType2: '',
      ptfCostType3: '',
      fscFeeScheduleName: '',
      taxSchedule: '',
      priceComparisonCode: '',
      ptfPatientPaySchedule: '',
      ptfWithholdSchedule: '',
    },
    hideDrugCost: true,
    hideFeeSched: true,
    hideTaxSched: true,
    hidePriceComCode: true,
    hidePatientPaySched: true,
    hideWitholdSched: true,
  };

  constructor(props: SnapshotProps) {
    super(props);
    this.terminal = new OptumTerminalCommunicationService();
  }

  showLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.add('show');
    }
  }

  hideLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.remove('show');
    }
  }

  componentDidMount(): void {
    this.getData();
  }

  /**
   * Calls backend to get data to populate table
   */
  getData() {
    this.formData = this.props.params;

    const snapshot = this.formData.fields;
    const priceTableDetails = {
      ptfPricingTableName: snapshot["dtl.ptfPricingTableName"],
      ptfDescription: snapshot["dtl.ptfDescription"],
      ptfFromDate: snapshot["dtl.ptfFromDate"],
      ptfThruDate: snapshot["dtl.ptfThruDate"],
      ptfStatus: snapshot["dtl.wrkDescription8"],
      ptfCostSource1: snapshot["dtl.ptfCostSource1"],
      ptfCostSource2: snapshot["dtl.ptfCostSource2"],
      ptfCostSource3: snapshot["dtl.ptfCostSource3"],
      ptfCostType1: snapshot["dtl.ptfCostType1"],
      ptfCostType2: snapshot["dtl.ptfCostType2"],
      ptfCostType3: snapshot["dtl.ptfCostType3"],
      fscFeeScheduleName: snapshot["dtl.fscFeeScheduleName"],
      taxSchedule: snapshot["dtl.taxSchedule"],
      priceComparisonCode: snapshot["dtl.workCondition8"],
      ptfPatientPaySchedule: snapshot["dtl.ptfPatientPaySchedule"],
      ptfWithholdSchedule: snapshot["dtl.ptfWithholdSchedule"],
    };
    this.setState({ priceTable: priceTableDetails });
  }

  toggleExpandable(obj) {
    this.setState(obj);
  }


  getDrugCostSourceType() {
    const { priceTable, hideDrugCost } = this.state;

    if (hideDrugCost) {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideDrugCost: false })}>►</td>
              <td> <strong>Drug Cost Source And Type</strong></td>
            </tr>
          </table>
        </div>
      )
    } else {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideDrugCost: true })}>▼</td>
              <td> <strong>Drug Cost Source And Type</strong></td>
            </tr>
          </table>
          <br/>
          <table className="display">
            <tbody>
              <tr>
                <td></td>
                <td><strong>Drug Cost Source</strong></td>
                <td><strong>Drug Cost Type</strong></td>
              </tr>
              <tr>
                <td>1.</td>
                <td>{priceTable.ptfCostSource1 || "-"} </td>
                <td>{priceTable.ptfCostType1 || "-"} </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>{priceTable.ptfCostSource2 || "-"} </td>
                <td>{priceTable.ptfCostType2 || "-"} </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>{priceTable.ptfCostSource3 || "-"} </td>
                <td>{priceTable.ptfCostType3 || "-"} </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }

  }

  getFeeSchedule() {
    const { priceTable, hideFeeSched } = this.state;

    if (hideFeeSched) {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideFeeSched: false })}>►</td>
              <td> <strong> Fee Schedule </strong></td>
            </tr>
          </table>
        </div>
      )
    } else {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideFeeSched: true })}>▼</td>
              <td> <strong> Fee Schedule </strong></td>
            </tr>
          </table>
          <br/>

          <table className='display'>
            <tbody>
              <tr>
                <td>
                  Fee Schedule : {priceTable.fscFeeScheduleName}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  }

  getTaxSchedule() {
    const { priceTable, hideTaxSched } = this.state;
    if (hideTaxSched) {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideTaxSched: false })}>►</td>
              <td> <strong> Tax Schedule </strong></td>
            </tr>
          </table>
        </div>
      )
    } else {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideTaxSched: true })}>▼</td>
              <td> <strong> Tax Schedule </strong></td>
            </tr>
          </table>
          <br/>
          <table className='display'>
            <tbody>
              <tr>
                <td>
                  Tax Schedule : {priceTable.taxSchedule}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  }

  getPriceCompCode() {
    const { priceTable, hidePriceComCode } = this.state;
    if (hidePriceComCode) {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hidePriceComCode: false })}>►</td>
              <td> <strong> Price Comparision Code </strong></td>
            </tr>
          </table>
        </div>
      )
    } else {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hidePriceComCode: true })}>▼</td>
              <td> <strong> Price Comparision Code </strong></td>
            </tr>
          </table>
          <br/>
          <table className='display'>
            <tbody>
              <tr>
                <td>
                  Price Comparision Code : {priceTable.priceComparisonCode}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  }

  getPatientPaySchedule() {
    const { priceTable, hidePatientPaySched } = this.state;
    if (hidePatientPaySched) {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hidePatientPaySched: false })}>►</td>
              <td> <strong> Patient Pay Schedule </strong></td>
            </tr>
          </table>
        </div>
      )
    } else {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hidePatientPaySched: true })}>▼</td>
              <td> <strong> Patient Pay Schedule </strong></td>
            </tr>
          </table>
          <br/>
          <table className='display'>
            <tbody>
              <tr>
                <td>
                  Patient Pay Schedule : {priceTable.ptfPatientPaySchedule}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  }


  getWithholdSchedule() {
    const { priceTable, hideWitholdSched } = this.state;
    if (hideWitholdSched) {
      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideWitholdSched: false })}>►</td>
              <td> <strong> Withhold Schedule </strong></td>
            </tr>
          </table>
        </div>
      )
    } else {

      return (
        <div>
          <br/>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideWitholdSched: true })}>▼</td>
              <td> <strong> Withhold Schedule </strong></td>
            </tr>
          </table>
          <br/>
          <table className='display'>
            <tbody>
              <tr>
                <td>
                  Withhold Schedule : {priceTable.ptfWithholdSchedule}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      )
    }
  }



  getPriceScheduleSnapshot(): React.ReactElement {
    const { priceTable } = this.state;

    return (
      <React.Fragment>
        <hr></hr>

        <div className="p-grid">
          <div className="p-col-2">
            <table>
              <tbody>
                <tr>
                  <td><strong>Table: </strong></td>
                  <td></td>
                  <td></td>
                  <td>{priceTable.ptfPricingTableName}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-col-4">
            <table>
              <tbody>
                <tr>
                  <td><strong>Description: </strong></td>
                  <td></td>
                  <td></td>
                  <td>{priceTable.ptfDescription}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-col">
            <table>
              <tbody>
                <tr>
                  <td><strong>From Date: </strong></td>
                  <td></td>
                  <td></td>
                  <td>{priceTable.ptfFromDate}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-col">
            <table>
              <tbody>
                <tr>
                  <td><strong>Thru Date: </strong></td>
                  <td></td>
                  <td></td>
                  <td>{priceTable.ptfThruDate}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-col">
            <table>
              <tbody>
                <tr>
                  <td><strong>Status: </strong></td>
                  <td></td>
                  <td></td>
                  <td>{priceTable.ptfStatus}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col">
            <div>
              {this.getDrugCostSourceType()}
              {this.getFeeSchedule()}
              {this.getTaxSchedule()}
            </div>
          </div>
          <div className="p-col">
            {this.getPriceCompCode()}
            {this.getPatientPaySchedule()}
            {this.getWithholdSchedule()}
          </div>
        </div>
      </React.Fragment >
    )
  }

  /**
   *
   */
  render(): React.ReactElement {
    return this.getPriceScheduleSnapshot();
  }
}

export default Rcptf031Snapshot;