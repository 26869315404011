const CryptoJs = require('crypto-js');
import * as AuthProxy from "../../security/AuthProxy";

export function getTenantIds()
{
    return fetch(`./portunus/tenantId`,{
        headers: {
            'session-id': AuthProxy.getSessionId() as string,
            'authtype': AuthProxy.getAuthType() as string
        }
    });
}
