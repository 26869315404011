import * as React from 'react';
import ReactDOM from 'react-dom';
import './AppFooter.css';
import Logout from '../logout/Logout';
import 'primeflex/primeflex.css';

declare global {
  interface Window { runConfig: any; }
}

export interface AppFooterState {
  currentDateTime: String;
}

export interface AppFooterProps {
  screenDef: any;
}

declare global {
  interface Window { runConfig: any; }
}
/**
*
*/
export interface AppFooterProps {
  userName: any;
}
class AppFooter extends React.Component<AppFooterProps, AppFooterState> {
  private logoSrc = './images/Optum-Rx-wht-RGB.svg';
  private intervalID: NodeJS.Timeout

  constructor(props: AppFooterProps) {
    super(props);
    this.state = { currentDateTime: Date().toLocaleString() };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      currentDateTime: new Date().toLocaleString('en-US', { hour12: false })
    });
  }

  openHelpText() {
    const helpPage = "/help/NextGen_Price/index.htm#t=Price%2F"+ this.props.screenDef.programName + ".htm";
    window.open(
      helpPage,
      'Help'
    );
    return;
  }

  render(): React.ReactElement {
    const manageNavigationMessage = function (this: any, e: MessageEvent) {
      if(e.data.message === "getProgramName") {
        window.parent.postMessage(
        {
          message: "sendProgramName",
          programName: this.props.screenDef.programName,
          application: "Price"
        }, '*');
      }
    }
    window.addEventListener("message", manageNavigationMessage.bind(this));

    const env = process.env.REACT_APP_API_ENV;
    let displayUser;
    let logoutDisplay;
    const version=window['version'];
    const doLogout = () => {
      //sessionStorage.clear();
      window.parent.postMessage("logout", '*');
      return ReactDOM.render(
        <React.StrictMode>
          <Logout />
        </React.StrictMode>,
        document.getElementById('root')
      );
    }
    if (this.props.userName) {
      // split username to get first name only
      const fullName = this.props.userName.split(" ");
      const firstName = fullName[0];
      displayUser = <button> Hello {firstName} </button>;
      logoutDisplay = <span className="logout"><span className="section-divider-right"></span><button onClick={doLogout} >Sign Out</button></span>;
    }
    else {
      displayUser = <button>Sign In</button>;
    }

    return (
      <React.Fragment>
        <footer className="footer--global copyright">
          <div className="container">
            <div className="legal-bar">
              <div className="row">
                <div className="legal-note">© {(new Date().getFullYear())} Optum Rx, Inc. All rights reserved</div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className={env.toLowerCase()}>
              <div className="d-flex">
                <div className="logo">
                  <span className="logo-title">
                    <img src={this.logoSrc} className="optum-logo" alt="OptumRx Logo"></img>
                    <span className="app-title">RxClaim Next<b>Gen</b> {version}</span>
                  </span>
                </div>
                <div className="helpLogin">
                  <span className="buttons">
                    <span className="user">
                      {displayUser}
                    </span>
                    <span className="button-divider"></span>
                    <span className="help">
                      <button onClick={() => this.openHelpText()}>Help</button>
                    </span>
                    <span>
                      {logoutDisplay}
                    </span>
                  </span>
                </div>
                <span className="button-divider-logout"></span>
                <div className="env">
                  <span className="environment-label">{env.toUpperCase()}</span>
                </div>
                <span className="date-time">
                  {this.state.currentDateTime}
                </span>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default AppFooter;
