type programInformation = {
    programName: string,
    inputParameterClass?: string,
    inputParameterNames?: string[]
}

type programInformations = {
    [name: string]: programInformation
}

const programs: programInformations = {
    RCPRC003: {
        programName: 'com.optum.file.prcpriceschedule.program.rcprc003dsppricesched.IRcprc003DspPriceSchedService'
    },
    RCPTF031: {
        programName: 'com.optum.file.ptfpricingtable.program.rcptf031dsppricingtabl.IRcptf031DspPricingTablService'
    },
    RCDC3001: {
        programName: 'com.optum.file.dc3drugcostschedule.program.rcdc3001drugcostsched.IRcdc3001DrugCostSchedService'
    },
    RCDC5001: {
        programName: 'com.optum.file.dc5drugcostcompsched.program.rcdc5001mntdrgcmpcst.IRcdc5001MntdrgcmpcstService'
    },
    RCPS7001: {
        programName: 'com.optum.file.ps7pricebasisschedule.program.rcps7001dspprcbassch.IRcps7001DspPrcBasSchService'
    },
    RCMCL001: {
        programName: 'com.optum.file.mclmaclist.program.rcmcl001dispmaclists.IRcmcl001DispMacListsService'
    },
    RCMLS001: {
        programName: 'com.optum.file.mlsmaclistschedule.program.rcmls001mntmls.IRcmls001MntMlsService'
    },
    RCMLI001: {
        programName: 'com.optum.file.mlimaclistschedident.program.rcmli001mntmli.IRcmli001MntMliService'
    },
    RCTAX001: {
        programName: 'com.optum.file.taxtaxschedule.program.rctax001disptaxsched.IRctax001DispTaxSchedService'
    },
    RCWSH001: {
        programName: 'com.optum.file.wshwithholdschedule.program.rcwsh001mntwithholdsch.IRcwsh001MntWithholdSchService'
    },
    RCADS001: {
        programName: 'com.optum.file.adsamountdueschedule.program.rcads001mntamtduesch.IRcads001MntAmtDueSchService'
    },
    RCFSH001: {
        programName: 'com.optum.file.fshfeescheduleheader.program.rcfsh001maintfeesched.IRcfsh001MaintFeeSchedService'
    },
    RCSFS001: {
        programName: 'com.optum.file.sfsstatefeeschedule.program.rcsfs001dspstfeescact.IRcsfs001DspStfeescActService'
    },
    RCSFT001: {
        programName: 'com.optum.file.sftprofservicefeetbl.program.rcsft001mntprfsrvfee.IRcsft001MntPrfSrvFeeService'
    },
    RCSFH001: {
        programName: 'com.optum.file.sfhprfsrvfeeschedhdr.program.rcsfh001mntprfsrvfeesch.IRcsfh001MntPrfsrvfeeschService'
    },
    RCAFS001: {
        programName: 'com.optum.file.afsadminfeeschedule.program.rcafs001mntadminfeesc.IRcafs001MntAdminFeeScService'
    },
    RCAFR001: {
        programName: 'com.optum.file.afradminfeeprofile.program.rcafr001mntadminfeeprof.IRcafr001MntAdminfeeprofService'
    },
    RCRMT001: {
        programName: 'com.optum.file.rmtretailandmailtable.program.rcrmt001dsprmtmaint.IRcrmt001DspRmtMaintService'
    },
    RCTRC001: {
        programName: 'com.optum.file.trctaxratecodetable.program.rctrc001dsptrcmntactv.IRctrc001DspTrcMntActvService'
    },
    RCSPT001: {
        programName: 'com.optum.file.sptstatepricingtable.program.rcspt001dspsptmntactv.IRcspt001DspSptMntActvService'
    },
    RCRBL001: {
        programName: 'com.optum.file.rblposrebatelist.program.rcrbl001mntrbl.IRcrbl001MntRblService'
    },
    RCRP1001: {
        programName: 'com.optum.file.rp1rxpriceschedule.program.rcrp1001mntrxpriceschd.IRcrp1001MntRxpriceSchdService'
    },
    RCRRD001: {
        programName: 'com.optum.file.rrdrxrestrictionsched.program.rcrrd001dsprestrctnschd.IRcrrd001DsprestrctnSchdService'
    },
    RCNRB001: {
        programName: 'com.optum.file.nrbphrmntwkrateschedule.program.rcnrb001phrmntwkratesch.IRcnrb001PhrmntwkRateschService'
    },
    RCSCH001: {
        programName: 'com.optum.file.schsourcecodeheader.program.rcsch001dispcostsrctyl.IRcsch001DispCostsrctyLService'
    },
    RCTXP001: {
        programName: 'com.optum.file.txptaxtableforpharntw.program.rctxp001mnttaxandphar.IRctxp001MntTaxAndPharService'
    },
    RCDXR001: {
        programName: 'com.optum.file.dxrpartdposrebate.program.rcdxr001prtdrebateact.IRcdxr001PrtdRebateActService'
    }


};

export function getProgramParameters(programName: string): programInformation | undefined {
    return programs[programName];
}


export function getProgramUrl(programName: string, parameters?: string[]): string | undefined {
    // get program informations
    let url = undefined;
    // return program url
        const encodedParameters: string[] = parameters && parameters.length > 0 ? parameters.map(elt=> encodeURIComponent(elt as string)) : [];
        const urlParameters = encodedParameters.length > 0 ? "/" + encodedParameters.join("/") : "";
        url = `/program/${programName}${urlParameters}`
    
    console.log("Navigating to " + url);
    return url;
}

