import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { TerminalCommunicationService, processErrors, endOfProcess } from '@fresche/terminal-lib';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Messages } from 'primereact/messages';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import '@fresche/common-lib/dist/assets/variables.css';
import '@fresche/ui-lib-react/dist/index.css';
import './App.css';
import './assets/css/fresche-layout.css';
import './assets/css/styles.css';
import './assets/css/custom.css';
import { CUSTOM_ROUTE_PATH } from './App.routes';

declare global {
  interface Window { config: any; }
}

let terminal: TerminalCommunicationService;
let userWantToQuit: boolean;

class App extends React.Component<any, any> {
  messageService: Messages;

  /**
   *
   */
  constructor(props: any) {
    super(props);

    // Easy hack by replace previous name window.config by the props.env received from the JSON Config file.
    window.config = props.env;
    terminal = new TerminalCommunicationService();

    // check if we are redirected to on-error OR inside the custom folder for your own screen
    
    // if ((window.location.href.split('?')[0].endsWith('on-error') === false) && (window.location.href.split('?')[0].includes(CUSTOM_ROUTE_PATH) === false)) {
    //   // Check in config if tenant-id is there, otherwise in the Url...nothing 
    
    if (window.location.href.indexOf("/program/") < 0) {
      // Do not initialize with empty if accessing a direct url.
      const myParam = window.config && window.config['backend'] && window.config['backend']['tenant-id'] !== '' ? window.config['backend']['tenant-id'] : 'default';
      const additionalHeaders = { 'tenant-id': myParam };
      terminal.init({}, {}, window.config);
    }

    this.handleConfirmationKeys = this.handleConfirmationKeys.bind(this);
  }

  /**
   *
   */
  componentDidMount(): void {
    this.initializeApp();
  }

  /**
   *
   */
  initializeApp(): void {
    // Handles session errors
    processErrors.subscribe((m: any): void => {
      if (m && m['code'] && m['message']) {
        this.messageService.clear();
        this.messageService.show({ severity: 'error', summary: `Process Error ${m['code']} : `, detail: m['message'] });
        processErrors.next(null);
      }
    });

    // Handle end of process
    endOfProcess.subscribe((data: any): void => {
      if (data && data.redirectUrl) {
        terminal.gotoLocation(data.redirectUrl);
        endOfProcess.next(null);
      }
    });

    document.addEventListener('keydown', this.handleConfirmationKeys);
    window.addEventListener('beforeunload', this.beforeUnload);
    window.addEventListener('unload', this.unload);
  }

  /**
   *
   */
  componentWillUnmount(): void {
    if (processErrors) {
      processErrors.unsubscribe();
    }

    if (endOfProcess) {
      endOfProcess.unsubscribe();
    }

    document.removeEventListener('keydown', this.handleConfirmationKeys);
    window.removeEventListener('beforeunload', this.beforeUnload);
    window.removeEventListener('unload', this.unload);
  }

  beforeUnload(e: any) {
    const askToLeave = terminal.getShowLeavingQuestion();

    // In all case we put this value to true, user code should set it to false to by pass the question
    terminal.setShowLeavingQuestion(true);

    if (askToLeave === true) {
      userWantToQuit = true;

      // User is leaving or closing the tab (browser)
      e.preventDefault();
      e.returnValue = '';
    }
  }

  unload(_e: any) {
    if (userWantToQuit === true) {
      userWantToQuit = false;
      terminal.stopProcess();

      // Patch
      const time = Date.now();
      while ((Date.now() - time) < 1000) {

      }
      return true;
    }
  }

  /**
   * accept a confirmation on Y
   * rejects a confirmation on N
   */
  handleConfirmationKeys(event: KeyboardEvent): void {
    // the tabulation needs to loop trhough the screen onlly
    if (event.code === 'Tab') {
      let rootToSearch: any = document;

      // if we are in a popup dialog then we need to search inside this dialog (not in all DOM)
      if (document.querySelector('div.p-dialog') !== null) {
        rootToSearch = document.querySelector('div.p-dialog');
      }

      // list all focusable elements
      const editableSelectors = [
        'input:not([disabled]):not([readonly]):not([tabindex="-1"])',
        'select:not([disabled]):not([readonly]):not([tabindex="-1"])',
        'textarea:not([disabled]):not([readonly]):not([tabindex="-1"])',
        '.p-checkbox-box:not([disabled]):not([readonly]):not([tabindex="-1"])'
      ];

      // Get all editable elements
      const editableFocusables = rootToSearch.querySelectorAll(editableSelectors.join(','));
      const firstEditableFocusable = editableFocusables[0];
      const lastEditableFocusable = editableFocusables[editableFocusables.length - 1];

      // Get all elements
      const allFocusable = rootToSearch.querySelectorAll('button:not([disabled]):not([readonly]), [href]:not([disabled]):not([readonly]), input:not([disabled]):not([readonly]), select:not([disabled]):not([readonly]), textarea:not([disabled]):not([readonly]), [tabindex]:not([tabindex="-1"])');
      const allFirstFocusable = allFocusable[0];
      const allLastFocusable = allFocusable[allFocusable.length - 1];

      // if shift key go to previous else goto next
      if (event.shiftKey) {
        // if the tab occur on first element, return to the last element of screen
        if (event.target === firstEditableFocusable || event.target === allFirstFocusable) {
          event.preventDefault();

          const toFocus: any = lastEditableFocusable;
          toFocus.focus();
        }
      } else {
        // if the tab occur on last element, return to the first element of screen
        if (event.target === lastEditableFocusable || event.target === allLastFocusable) {
          event.preventDefault();

          const toFocus: any = firstEditableFocusable;
          toFocus.focus();
        }
      }
    }
  }

  /**
   *
   */
  render(): React.ReactElement {
    return (
      <React.Fragment>

        <div id="loaderModal" className="modal">
          <ProgressSpinner className="loader" />
        </div>

        <Messages ref={(el: any): void => this.messageService = el} />

        <BrowserRouter basename={window.config && window.config['app'] ? window.config['app']['basedir'] : '/'}>
          <Router />
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
