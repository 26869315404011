const CryptoJs = require('crypto-js');
export function session(code: string): Promise<Response> {
    const requestBody = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'code': code })
    };
    return fetch(`./session?redirect_uri=${getHostUri() + "/"}&auth_type=${getAuthType()}`, requestBody);
}

export function loginRedirect() {
    window.location.href = `./login?redirect_uri=${getHostUri() + "/"}&auth_type=${getAuthType()}`;
      
}

export function portunusAbilities(tenant: any) {

    return fetch(`./portunus/abilities`, {
        headers: {
            'session-id': getSessionId() as string,
            'authtype': getAuthType() as string,
            'modulename': tenant,
        }
    });
}

export function getUserProfile() {
    return fetch(`./secure/user-profile?session-id=${getSessionId()}`);
}

export function logoutUser() {
    return fetch(`./secure/logout?auth_type=${getAuthType()}`);
}

export function validateSession() {
    return fetch(`./secure/validate-session`, {
        headers: {
            'session-id': getSessionId() as string
        }
    });
}
export function refresh() {
    return fetch(`./secure/refresh`);
}

export function getHostUri() {
    const locationArray = window.location.href.split("/");
    const protocol = locationArray[0];
    const host = locationArray[2];
    const redirectUri = protocol + "//" + host;
    return redirectUri;
}

export  function encrypt(text: string ){
    const pass =process==undefined?process.env.REACT_APP_API_AES_PASS:"default@optum"
    return CryptoJs.AES.encrypt(text,pass).toString();
}

export function decrypt(cipherText: string ){
    const pass =process==undefined?process.env.REACT_APP_API_AES_PASS:"default@optum"
    const bytes = CryptoJs.AES.decrypt(cipherText, pass);
    const text = bytes.toString(CryptoJs.enc.Utf8);
    return text;
}

export function getSessionId(){
    return decrypt(String(sessionStorage.getItem('sessionId')));
 }
 
 export function setSessionId(sessionId: string){
     sessionStorage.setItem("sessionId", encrypt(sessionId));
 }
 export function getAuthType(){
    return decrypt(String(sessionStorage.getItem('authType')));
 }
 
 export function setAuthType(authType: string){
     sessionStorage.setItem("authType", encrypt(authType));
 }

export function getTenantId(){
    return decrypt(String(sessionStorage.getItem('tenantId')));
}

export function setTenantId(tenantId: string){
    sessionStorage.setItem("tenantId", encrypt(tenantId));
}
