import * as React from 'react';
import {
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
import { model } from '@fresche/terminal-lib';
import routes from './App.routes';
import NotFound from './layout/not-found/NotFound';

declare global {
  interface Window { config: any; }
}

class Router extends React.Component<{ match: any, location: any, history: any }> {
  private currentRouteSub: any;

  componentDidMount(): void {
    if (window.location.href.split('?')[0].endsWith('on-error') === false) {
      this.currentRouteSub = model.subscribe((r: any): void => {
        if (r && r.screenId && r.data['messageDTO'] && r.data['messageDTO'].length > 0 && r.data['messageDTO'][0].messageText.match('^Not Authorized for this operation$')) {
          this.props.history.push('/');
          this.props.history.push('no-auth', {
            screenId: 'no-auth'
          });
        } else if (r && r.screenId) {
          this.props.history.push('/');
          this.props.history.push(r.screenId, {
            screenId: r.screenId, formData: r.data
          });
        }
      });
    }
  }

  componentWillUnmount(): void {
    if (this.currentRouteSub) {
      this.currentRouteSub.unsubscribe();
    }
  }

  render(): React.ReactElement {
    return (
      <Switch>
        {routes.map((route: any, i: any): any => (
          <Route
            key={i}
            path={route.path}
            component={route.component}
          />
        ))}
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default withRouter(Router);
