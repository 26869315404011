/**
 *
 */
declare global {
  interface Window { config: any; }
}

const SEVERITY_ERROR = 'error';
const SEVERITY_WARNING = 'warning';

export class DebugService {
  /**
   *
   */
  private checkInput(screenDef: any, formData: any): any[] {
    const result: any[] = [];

    // Loop each input
    for (const input of screenDef.inputs) {
      // check binding with payload
      if (input.field in formData.fields === false) {
        result.push({
          "id": 1000,
          "severity": SEVERITY_ERROR,
          "description": `Input with the field '${input.field}' does not have any data binded in the payload`,
          "comment": "",
        });
      } else {
        // Validate data type
        switch (input.type) {
          case 'label': {

          }
            break;

          case 'string':
          case 'multiline': {
            if (typeof formData.fields[input.field] !== 'string') {
              result.push({
                "id": 1000,
                "severity": SEVERITY_ERROR,
                "description": `Input with the field '${input.field}' is bind to a data having a different type`,
                "comment": `Expected: 'string', Actual: '${typeof formData.fields[input.field]}'`,
              });
            }
          }
            break;

          case 'number': {
            if (typeof formData.fields[input.field] !== 'number') {
              result.push({
                "id": 1000,
                "severity": SEVERITY_ERROR,
                "description": `Input with the field '${input.field}' is bind to a data having a different type`,
                "comment": `Expected: 'number', Actual: '${typeof formData.fields[input.field]}'`,
              });
            }
          }
            break;

          case 'datetime':
          case 'date':
          case 'time': {
            if (typeof formData.fields[input.field] !== 'string') {
              result.push({
                "id": 1000,
                "severity": SEVERITY_ERROR,
                "description": `Input with the field '${input.field}' is bind to a data having a different type`,
                "comment": `Expected: 'string', Actual: '${typeof formData.fields[input.field]}'`,
              });
            } else {
              // If type is a string, we need to validate if we can transform into a valid date

            }
          }
            break;

          case 'dropdown': {

          }
            break;

          default: {
            result.push({
              "id": 1000,
              "severity": SEVERITY_ERROR,
              "description": `Input with the field '${input.field}' does not have a valid type`,
              "comment": "",
            });
          }
            break;
        }
      }
    }

    return result;
  }

  /**
   *
   */
  private checkExtraPayloadInfo(screenDef: any, formData: any): any[] {
    const result: any[] = [];
    return result;
  }

  /**
   *
   */
  private checkFunctionKeys(screenDef: any, formData: any): any[] {
    const result: any[] = [];
    return result;
  }

  /**
   *
   */
  private checkGrid(screenDef: any, formData: any): any[] {
    const result: any[] = [];

    /*
    screenDef.gridDefinition.columns.forEach((col: any) => {
      // check binding with payload
      let found = false;
      formData.subFileFields.forEach((f: any) => {
        if (f.field === col[0].field)
      });
      
      if (col[0].field in formData.subFileFields.field === false) {
        result.push({
          "id": 1000,
          "severity": SEVERITY_ERROR,
          "description": `Grid column with the field ${col[0].field} does not have any data binded in the payload`,
          "comment": "",
        });
      }
      
    });
*/

    return result;
  }

  /**
   * 
   */
  private checkConditions(screenDef: any, formData: any): any[] {
    const result: any[] = [];
    return result;
  }

  /**
   *
   */
  public getErrorAndWarning(screenDef: any, formData: any): any[] {
    // 1) Check input binding (including grid)
    // 2) Check extra info in payload for Warnings
    // 3) Check function key
    // 4) Check conditions
    let result: any[] = [];

    if (screenDef) {
      const checkInputBindingResult = this.checkInput(screenDef, formData);
      if (checkInputBindingResult.length > 0) {
        result = result.concat(checkInputBindingResult);
      }

      const checkExtraPayloadInfoResult = this.checkExtraPayloadInfo(screenDef, formData);
      if (checkExtraPayloadInfoResult.length > 0) {
        result = result.concat(checkExtraPayloadInfoResult);
      }

      const checkFunctionKeysResult = this.checkFunctionKeys(screenDef, formData);
      if (checkFunctionKeysResult.length > 0) {
        result = result.concat(checkFunctionKeysResult);
      }

      const checkGridResult = this.checkGrid(screenDef, formData);
      if (checkGridResult.length > 0) {
        result = result.concat(checkGridResult);
      }

      const checkConditionsResult = this.checkConditions(screenDef, formData);
      if (checkConditionsResult.length > 0) {
        result = result.concat(checkConditionsResult);
      }
    }
    return result;
  }
}