import * as React from 'react';

/**
 * Sample custom screen provided with the frontend for your own usage.  
 * This type of screen is not attached to the generated backend.  Use as you wish and make your own logic.
 */
class CustomSampleScreen<P = {}, S = any> extends React.Component<any, any> {
  /**
   *
   */
  render(): React.ReactElement {
    return (
      <React.Fragment>
        <p>This is your sample screen</p>
      </React.Fragment >
    );
  }
}

export default CustomSampleScreen;
