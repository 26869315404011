import Menu from './layout/menu/Menu';
import Reports from './layout/reports/Reports';
import OnError from './layout/on-error/OnError';
import NoAuth from './layout/no-auth/NoAuth';
import Logout from './layout/logout/Logout';
import BaseViewComponent from './base/view/base-view';
import CustomRouter from './custom/CustomRouter';
import StartProgram from './components/programs/StartProgram';
import JavaToPresto from './layout/custom/JavaToPresto';

// Name of the custom url path for your own screen (not generated)
export const CUSTOM_ROUTE_PATH = '/custom';

const routes = [
  {
    path: '/masterMenu',
    component: Menu
  },
  {
    path: '/reportMenu',
    component: Reports
  },
  {
    path: '/on-error',
    component: OnError
  },
  {
    path: '/program/**',
    component: StartProgram
  },
  {
    path: '/logout',
    component: Logout
  },
  {
    path: '/no-auth',
    component: NoAuth
  },
  {

    path: '/pn3phnworkfile/rcpn3003dspnetworktocpy',

    component: JavaToPresto

  },
  {
    // custom url (special usage)
    path: CUSTOM_ROUTE_PATH,
    component: CustomRouter
  },
  {
    path: '**',
    component: BaseViewComponent
  }
];

export default routes;
