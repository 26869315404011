import * as React from 'react';
import { TerminalCommunicationService, model } from '@fresche/terminal-lib';
import { FGrid } from '@fresche/ui-lib-react';
import AppHeader from '../app-header/AppHeader';
import { Button } from 'primereact/button';
import { Subscription } from 'rxjs';

export default class Reports extends React.Component<any, any> {
  protected terminal = new TerminalCommunicationService();
  public eofSub: Subscription;
  public formData: any;
  public screenDef: any;
  private modelSub: Subscription; // Mod-e-l
  private conditions: any;

  constructor(props: any) {
    super(props);

    // Because all stuff to manage condition when they are declared are not in this page then we set conditions directly instead of using initialDisplay on the column
    this.conditions = {
      grid: {
        reportName: {
          protect: true,
        }
      }
    }

    this.screenDef = {
      gridDefinition: {
        selectionMode: 'single',
        paginator: true,
        exportable: false,
        columns: [
          [
            {
              field: 'reportName',
              type: 'string',
              header1: 'reportName',
              size: 40,
              sortable: false
            },
          ]
        ],
        actions: [
          {
            key: '10',
            command: 'print',
            label: 'Print Rpt.'
          },
          {
            key: '11',
            command: 'download',
            label: 'Download Rpt.'
          },
          {
            key: '12',
            command: 'delete',
            label: 'Delete Rpt.'
          }
        ]
      }
    };

    this.clickAction = this.clickAction.bind(this);
    this.showReports = this.showReports.bind(this);
    this.openNewWindow = this.openNewWindow.bind(this);

    this.modelSub = model.subscribe((m: any): any => {
      if (m) {
        if (m.screenId && m.screenId === '_screenConfirm') {
        } else {
          if (m.data) {
            this.formData = m.data;
          }
        }
      }
    });
  }

  /**
   * Stops the loaders.
   */
  hideLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.remove('show');
    }
  }

  /**
   *
   */
  componentDidMount(): void {
    this.showReports();
    this.hideLoader();
  }

  /**
   * 
   */
  componentWillUnmount(): void {
    if (this.eofSub) {
      this.eofSub.unsubscribe();
    }
    if (this.modelSub) {
      this.modelSub.unsubscribe();
    }
  }

  /**
   * 
   */
  showReports(): void {
    if (this.formData && this.formData['fields'] && this.formData['fields']['report']) {
      if (this.formData['fields']['lastAction'] === 'print' && sessionStorage.getItem('printed') === 'false') {
        this.openNewWindow(this.formData['fields']['report'], 'target');
      } else if (this.formData['fields']['lastAction'] === 'download' && sessionStorage.getItem('printed') === 'false') {
        this.openNewWindow(this.formData['fields']['report'], 'download');
      }
    }

    sessionStorage.setItem('printed', 'true');
  }

  /**
   *
   */
  openNewWindow(report: string, action: string) {
    const blob = new Blob([report], { type: 'text/text;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    if (link.download !== undefined) {
      link.setAttribute('href', url);

      if (action === 'target') {
        link.setAttribute('target', '_blank');
      } else {
        link.setAttribute('download', this.formData['fields']['optionSelected'] || 'unnamed');
      }
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  /**
   *
   */
  handleMasterMenuClick(): void {
    this.formData['fields']['reportOption'] = 'goToMenu';
    this.formData['fields']['goToReportMenu'] = false;
    this.terminal.reply();
  }

  /**
   *
   */
  showGrid(): boolean {
    return this.formData;
  }

  /**
   *
   */
  clickAction(event: any) {
    sessionStorage.setItem('printed', 'false');
    this.formData['fields']['goToReportMenu'] = true;
    this.formData['fields']['reportOption'] = event.command;
    this.terminal.reply();
  }

  /**
   *
   */
  render(): React.ReactElement {
    return (
      <React.Fragment >
        <div style={{ padding: '130px 2.5% 14px' }}>
          <AppHeader
            screenDef={null}
            formData={null}
          />
          <div className="container-fluid app-title-header">
            <h1>Report Menu - <span>DEFAULT</span></h1>
          </div>

          {this.screenDef.gridDefinition && (
            <div>
              {this.showGrid() && (
                <FGrid
                  model={this.formData}
                  screenDef={this.screenDef}
                  onActionButton={this.clickAction}
                  conditions={this.conditions}
                  onModelChange={(newModel: any): any => { this.formData = newModel; this.forceUpdate(); }}
                />
              )}
            </div>
          )
          }

          {<div className="container-fluid" style={{ width: '60%' }}>
            <Button
              label="EXIT TO TOP MENU"
              onClick={(e: any): void => {
                e.preventDefault();
                this.handleMasterMenuClick();
              }}
            />
          </div>
          }
        </div>
      </React.Fragment>
    );
  }
}
