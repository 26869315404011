import * as React from 'react';
import './Rcptf031Snapshot.css'
import { TerminalCommunicationService, model } from "@fresche/terminal-lib";

declare global {
  interface Window { config: any; }
}

export interface SnapshotProps {
  params: any;
}

class Rcdc5001Snapshot<P = {}, S = any> extends React.Component<SnapshotProps, any> {
  backendUrl: string;
  formData;

  state = {
    hideSequences: true,
    drgCostCompSched: {
      schedule: '',
    description: '',
    status: '',
    sequences:[],
   
    }

  };

  constructor(props: SnapshotProps) {
    super(props);
  }

  showLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.add('show');
    }
  }

  hideLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.remove('show');
    }
  }

  componentDidMount(): void {
    this.getData();
  }

  /**
   * Calls backend to get data to populate table
   */
  getData() {
    this.formData = this.props.params;
    const snapshot = this.formData.fields;
    const drgCstCompSchedSnapshotDtls = {
      schedule: snapshot["dtl.schedule"],
      description: snapshot["dtl.description"],
      status: snapshot["dtl.status"],
      sequences: snapshot["dtl.sequences"],
      
      
    };
    this.setState({ drgCostCompSched: drgCstCompSchedSnapshotDtls });
  }

  toggleExpandable(obj) {
    this.setState(obj);
  }


  getSequences() {
    if (this.state.hideSequences) {
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideSequences: false })}>►</td>
              <td> 
              <a onClick = {()=> {this.replyToBackend(this.formData,'dtl.sequences')}}>
                                                <h5>Sequences</h5></a></td>
            </tr>
          </table>
        </div>
      )
    } else {
    const list = this.state.drgCostCompSched.sequences;
    let items = [];
    if(list != undefined) {
      items = list.map(ds =>
        <tr key={ds}>
           <td>{ds.dc6Sequence}</td>
          <td>{ds.dc6DrugCostSource}</td>
          <td>{ds.dc6DrugCostType}</td>
          <td>{ds.dc6MacList}</td>
          <td>{ds.dc6StatePricing}</td>
          <td>{ds.dc6StatePricingTable}</td>
          <td>{ds.dc6DrugCostPercent}</td>
          <td>{ds.dc6AvailabilityRule}</td>
          <td>{ds.dc6_340bQualifierFlag}</td>
          <td>{ds.dc6SuperNetworkId}</td>
        </tr>
      )
    }
      return (
        <div>
          <table>
            <tr>
              <td onClick={() => this.toggleExpandable({ hideSequences: true })}>▼</td>
              <td> 
              <a onClick = {()=> {this.replyToBackend(this.formData,'dtl.sequences')}}>
                                                <h5>Sequences</h5></a></td>
            </tr>
          </table>
          <table className="display">
          <tbody>
            <tr>
              <td>
                <strong>Drug Cost Seq</strong>
              </td>
              <td>
                <strong>Drug Cost Source</strong>
              </td>
              <td>
                <strong>Drug Cost Code</strong>
              </td>
              <td>
                <strong>MAC List</strong>
              </td>
              <td>
                <strong>State Pricing</strong>
              </td>
              <td>
                <strong>Pricing Table</strong>
              </td>
              <td>
                <strong>% Rate</strong>
              </td>
              <td>
                <strong>Availability Rule</strong>
              </td>
              <td>
                <strong>340B</strong>
              </td>
              <td>
                <strong>Super Network</strong>
              </td>
            </tr>
            {items}
          </tbody>
          </table>
        </div>
      ) 
    }

  }

 

  replyToBackend = (newFormData: any, field: any ) => {
    const terminal = new TerminalCommunicationService();
    newFormData['cmdKey'] = '04';
    newFormData['cursorField'] = field;
    model.next({
      data: newFormData
    });
    terminal.reply();
  }

  getDrgCstCmpSchedSnapshot(): React.ReactElement {
    const { drgCostCompSched } = this.state;

    return (
      <React.Fragment>
        <hr></hr>

        <div className="p-grid">
          <div className="p-col-2">
            <table>
              <tbody>
                <tr>
                  <td><strong>Schedule: </strong></td>
                  <td></td>
                  <td></td>
                  <td>{drgCostCompSched.schedule}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-col-4">
            <table>
              <tbody>
                <tr>
                  <td><strong>Description: </strong></td>
                  <td></td>
                  <td></td>
                  <td>{drgCostCompSched.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-col">
            <table>
              <tbody>
                <tr>
                  <td><strong>Status: </strong></td>
                  <td>{drgCostCompSched.status}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col">
            <div>
              {this.getSequences()}
            </div>
          </div>
          
        </div>
      </React.Fragment >
    )
  }

  /**
   *
   */
  render(): React.ReactElement {
    return this.getDrgCstCmpSchedSnapshot();
  }
}

export default Rcdc5001Snapshot;