import * as React from 'react';
import Moment from 'moment';
import { model, TerminalCommunicationService } from '@fresche/terminal-lib';
import { FNumericInput } from '@fresche/ui-lib-react';

interface TextDisplayProps {
  input: any;
  formData: any;
  dropdownValue?: any;
}

/*
function replyToBackend(newFormData: any): void {
  // ... terminal lib need a refresh of its model
  model.next({
    data: newFormData
  });
  this.terminal.reply();
}
*/

function setActiveTab(e) {
  console.log(e.target);
}

function TextDisplay(props: TextDisplayProps) {
  const label = props.input?.label;
  const header1 = props.input?.header1;
  const isActionOnSelect = props.input?.actionOnFieldSelect;
  let value = props.formData['fields'][props.input.field];

  if(typeof value == "string"){
    if(value.indexOf("-") != -1){
      if(value.length == 10 && value.indexOf("-") == 4 && Moment(value, "YYYY-MM-DD", true).isValid()){
        const date = Moment(value);
        value = date.format("MM/DD/YYYY");
      }
    }
  }

  if (props.input?.type == "dropdown" && props.dropdownValue != null) {
    props.dropdownValue.forEach(e => {
      if (e?.value === props.formData['fields'][props.input.field]) {
        value = e?.label;
      }
    });
  }

  const terminal = new TerminalCommunicationService();

  const replyToBackend = (newFormData: any) => {
    console.log(newFormData);
    newFormData['cmdKey'] = '04';
    newFormData['cursorField'] = props.input.field;
    // ... terminal lib need a refresh of its model
    model.next({
      data: newFormData
    });
    console.log("Call backend");
    terminal.reply();
  }
  
  

  return (
    // not using 'display-val' class for numnber field becasue creating alignment issues.
    <div className={props.input.type === 'number' ? '' : 'display-val' }>
      <div>{label !== "" && value === undefined ? (
        <div><strong>{label}</strong></div>
      ): label !== "" ? (
        <div><strong>{label}</strong>:&nbsp;{value}</div>
      ): header1 !== "" ? (
        <div><strong>{header1}</strong>:&nbsp;{value}</div>
      ):
      <div>{isActionOnSelect && value != '*ALL' ? (
        <a onClick={() => replyToBackend(props.formData)}><div>{value}</div></a>
      ):
        <div className='input-no-border'>{props.input.type === 'number' ?
          <FNumericInput
            id={props.input.field}
            name={props.input.field}
            placeholder={props.input.placeholder}
            size={props.input.size}
            value={props.formData['fields'][props.input.field]}
            label={props.input.labelLocation === 'above' ? props.input.header1 : props.input.label}
            maxlength={props.input.displayFormat && props.input.displayFormat.textLength ? props.input.displayFormat.textLength : props.input.textLength}
            displayFormat={props.input.displayFormat}
            protect={true}
            readonly={true}
          /> : <div>{value} </div>
        }
        </div>
        }
        </div>
      }


      </div>
    </div>
  )}

  function selectProgram(): any {
    console.log("Here");
  }

export default TextDisplay;
