import React from 'react';
import ReactDOM from 'react-dom';
import process from 'process';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { generateFetchWithSession, generateFetchWithTenantId } from "./security/AuthentifiedFetch";
import * as AuthProxy from "./security/AuthProxy";
import jwt_decode from 'jwt-decode';
const CryptoJs = require('crypto-js');

const fileConfig = (process.env.NODE_ENV === 'development' ? process.env.PUBLIC_URL + './config.json' : './config.json');

window.parent.postMessage(
    {
        message: "getSessionInfo"
    }, '*');

    const manageNavigationMessage = function (e: MessageEvent) {
        console.log(e);
        if(e.data.message === "sendSessionInfo") {
            console.log(e.data.session_id);
            console.log(e.data.idp);
            AuthProxy.setSessionId(e.data.session_id);
            AuthProxy.setAuthType(e.data.idp);
            AuthProxy.setTenantId(e.data.tenantId);
        }
    }
    window.addEventListener("message", manageNavigationMessage.bind(this));
    
fetch(fileConfig)
  .then((res: any): any => {
    return res.json();
  })
  .then((env: any): any => {
        // @ts-ignore
        window['version'] = env.app.version
        if (env['authentication'] === 'no-oauth') {
            alert("WARNING : application is running without authentication!");
            console.log(env);
            window.fetch = generateFetchWithTenantId(env['tenant-id']);
            return ReactDOM.render(<App env={env} />, document.getElementById('root'));
        } else if (env['authentication'] === 'auth-proxy') {
            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');
            const sessionId = query.get('sessionId');
            const authType = query.get('authType');
            const tenantId = query.get('tenantId');
            console.log(window.sessionStorage);
            if (sessionId != null && sessionId != undefined) {
                AuthProxy.setSessionId(sessionId);
                AuthProxy.setAuthType(authType);
                AuthProxy.setTenantId(encodeURIComponent(tenantId))
            } else {
              window.parent.postMessage(
                {
                    message: "getSessionInfo"
                }, '*');

                const manageNavigationMessage = function (e: MessageEvent) {
                    console.log(e);
                    if(e.data.message === "sendSessionInfo") {
                        console.log(e.data.session_id);
                        console.log(e.data.idp);
                        AuthProxy.setSessionId(e.data.session_id);
                        AuthProxy.setAuthType(e.data.idp);
                        AuthProxy.setTenantId(e.data.tenantId);
                    }
                }

                window.addEventListener("message", manageNavigationMessage.bind(this));
                //alert("ERROR : relaunch pricing menu through NextGen UI");
                //return;
            }
            console.log("Session id -"+AuthProxy.getSessionId());
            if (!AuthProxy.getSessionId() && code) {
                AuthProxy.session(code)
                    .then((res: any) => res.json())
                    .then((data) => {                        
                        AuthProxy.setSessionId(data['sessionId']);
                        AuthProxy.setAuthType('msid');
                        window.fetch = generateFetchWithSession(AuthProxy.getSessionId(), 'msid');
                        return ReactDOM.render(<App env={env} />, document.getElementById('root'));
                    })
            } else if (AuthProxy.getSessionId()) {
                window.fetch = generateFetchWithSession(AuthProxy.getSessionId(), AuthProxy.getAuthType());
                return ReactDOM.render(<App env={env} />, document.getElementById('root'));
            }
        } else {
            alert("ERROR : authentication type not in list.");
        }
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
