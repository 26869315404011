import * as React from 'react';
import { TerminalCommunicationService } from '@fresche/terminal-lib';
import html2canvas from 'html2canvas';
import { Button } from 'primereact/button';
import { MegaMenu } from 'primereact/megamenu';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { DebugService } from './DebugService';
import './AppHeader.css';

/**
 *
 */
declare global {
  interface Window { config: any; }
}

/**
 *
 */
export interface AppHeaderProps {
  screenDef: any;
  formData: any;
  left?: (i: any) => React.ReactElement;
  right?: (i: any) => React.ReactElement;
}

/**
 *
 */
export interface AppHeaderState {
  infoVisible: boolean;
  displayScreenDef: boolean;
  image: any;
  logInformations: any[];
}

class AppHeader extends React.Component<AppHeaderProps, AppHeaderState> {
  private logoSrc = './images/logo.png';
  private debugService = new DebugService();
  dt: DataTable;

  menu: MegaMenu; // = React.createRef();
  rightmenu: OverlayPanel; // = React.createRef();

  private menuItems: any = [
    {
      label: 'Information',
      icon: 'pi pi-info',
      items: [[

      ]]
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: (): void => this.logout()
    }
  ];

  private terminal = new TerminalCommunicationService();

  /**
   *
   *
   */
  constructor(props: AppHeaderProps) {
    super(props);
    this.state = { infoVisible: false, displayScreenDef: false, image: undefined, logInformations: [] };

    this.severityTemplate = this.severityTemplate.bind(this);
    this.downloadInfo = this.downloadInfo.bind(this);
  }

  /**
   *
   */
  componentDidMount(): void {
    if (window.config && window.config['debug']) {
      this.setState({ logInformations: this.debugService.getErrorAndWarning(this.props.screenDef, this.props.formData) });
    }
    this.addScreenInfo();
    this.addDebug();
  }

  /**
   *
   */
  showDebugScreen(): void {
    this.rightmenu.hide();

    // Put in timeout to let the menu closing
    setTimeout(() => {
      html2canvas(document.body).then(canvas => {
        const croppedCanvas = document.createElement('canvas')
        const croppedCanvasContext = croppedCanvas.getContext('2d')
        // init data
        const cropPositionTop = 0
        const cropPositionLeft = 0
        const cropWidth = canvas.width
        const cropHeight = canvas.height

        croppedCanvas.width = cropWidth
        croppedCanvas.height = cropHeight

        croppedCanvasContext.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop,
        )

        const base64Image = croppedCanvas.toDataURL();
        this.setState({ image: base64Image });
      });
      this.setState({ displayScreenDef: !this.state.displayScreenDef });
    }, 250);
  }

  /**
   * Adds a list of debug options to the menu.
   */
  addDebug(): void {
    if (window.config && window.config['debug']) {
      this.menuItems.unshift(
        {
          label: 'Debug',
          icon: 'pi pi-exclamation-circle',
          items: [[

          ]]
        }
      );

      this.menuItems[0].items[0].push(
        {
          label: 'Debug tools',
          items: [
            {
              label: 'Screen information',
              icon: 'pi pi-external-link',
              command: (e: any) => { this.showDebugScreen() }
            }
          ],
        }
      );
    }
  }

  /**
   * Adds information about the current screen to the menu.
   * https://primefaces.org/primeng/showcase/#/megamenu
   */
  addScreenInfo(): void {
    const terminalProcess = this.terminal.getTerminalProcessInfo();
    let gatewayVersion = '';

    if (terminalProcess) {
      gatewayVersion = terminalProcess.gatewayVersion || '';
    }

    if (this.props.screenDef && this.props.formData) {
      this.menuItems[0].items[0].push(
        {
          label: 'Screen Information',
          items: [
            { label: `${this.props.screenDef.programName}`, disabled: true },
            { label: `${this.props.formData.programMode || ''}`, disabled: true }
          ]
        }
      );
    }

    if (window.config['app'] && window.config['app']['version']) {
      this.menuItems[0].items[0].push(
        {
          label: 'Application',
          items: [{ label: window.config['app']['version'], disabled: true }]
        }
      );
    }

    if (gatewayVersion) {
      this.menuItems[0].items[0].push(
        {
          label: 'Gateway',
          items: [{ label: gatewayVersion, disabled: true }]
        }
      );
    }
  }

  /**
   *
   */
  toggleInfo(): void {
    this.setState({ infoVisible: !this.state.infoVisible });
  }

  /**
   *
   */
  logout(): void {
    this.terminal.stopProcess();
  }

  onHideDebugScreen(): void {
    this.setState({ displayScreenDef: !this.state.displayScreenDef })
  }

  severityTemplate(rowData: any) {
    return <span className={`severity-badge status-${rowData.severity}`}>{rowData.severity}</span>;
  }

  downloadInfo(): void {
    // Download image
    const imgLink = document.createElement('a');
    imgLink.href = this.state.image;
    imgLink.download = `${this.props.screenDef.deviceName}.png`;
    imgLink.click();
    URL.revokeObjectURL(imgLink.href);

    // Download screen def
    const screenDefLink = document.createElement("a");
    const screenDefFile = new Blob([JSON.stringify(this.props.screenDef, null, 2)], { type: 'application/json' });
    screenDefLink.href = URL.createObjectURL(screenDefFile);
    screenDefLink.download = `${this.props.screenDef.deviceName}-screen-definition.txt`;
    screenDefLink.click();
    URL.revokeObjectURL(screenDefLink.href);

    // Download oayload
    const payLoadLink = document.createElement("a");
    const payLoadFile = new Blob([JSON.stringify(this.props.formData, null, 2)], { type: 'application/json' });
    payLoadLink.href = URL.createObjectURL(payLoadFile);
    payLoadLink.download = `${this.props.screenDef.deviceName}-payload.txt`;
    payLoadLink.click();
    URL.revokeObjectURL(payLoadLink.href);

    // Export grid
    this.dt.exportCSV();
  }

  /**
   * if DEBUG is true then we render a dialog with the debug info
   */
  renderDebugDialog(): React.ReactElement {
    const leftContents = (
      <React.Fragment>
        <Button label="Download" icon="pi pi-download" className="p-button-success" onClick={this.downloadInfo} />
      </React.Fragment>
    );

    const rightContents = (
      <React.Fragment>
        <Button icon="pi pi-times" className="p-button-danger" onClick={(e: any): any => { this.setState({ displayScreenDef: !this.state.displayScreenDef }); }} />
      </React.Fragment>
    );


    return (
      <React.Fragment>
        <Dialog className='debug-dialog' header="Debug information" maximizable={true} modal={true} visible={this.state.displayScreenDef} onHide={(): void => this.onHideDebugScreen()}>
          <Toolbar left={leftContents} right={rightContents} />
          <div className="flex-container">
            <div className="item">
              <Card title="Screenshot" className='card-screenshot tab-container-child'>
                <img src={this.state.image} alt={'Screenshot'} />
              </Card>
            </div>
            <div className="item">
              <Card title="Screen definition" className='tab-container-child'>
                <ScrollPanel>
                  <pre>{JSON.stringify(this.props.screenDef, null, 2)}</pre>
                </ScrollPanel>
              </Card></div>
            <div className="item">
              <Card title="Payload" className='tab-container-child'>
                <ScrollPanel>
                  <pre>{JSON.stringify(this.props.formData, null, 2)}</pre>
                </ScrollPanel>
              </Card></div>
          </div>
          <div className="container-warnings-errors">
            <Card title="Warnings and errors">
              <DataTable ref={(el) => { this.dt = el; }} exportFilename='Warning and errors.csv' value={this.state.logInformations} scrollable scrollHeight="200px" emptyMessage="No warnings/errors found." responsiveLayout="scroll">
                <Column field="severity" header="Severity" body={this.severityTemplate} style={{ maxWidth: '100px' }}></Column>
                <Column field="description" header="Description" style={{ minWidth: '200px' }}></Column>
                <Column field="comment" header="Additional info" style={{ minWidth: '200px' }}></Column>
              </DataTable>
            </Card>
          </div>
        </Dialog>
      </React.Fragment>
    )
  }

  /**
   *
   */
  render(): React.ReactElement {
    return (
      <React.Fragment>
        <div className="header-content">
          <div className="left">
            {this.props.left ? this.props.left(this) : null}
            <a id="logo">
              <img src={this.logoSrc} alt="Home" onClick={(e: any): void => {
                e.preventDefault();
                this.terminal.gotoLocation('/');
              }} />
            </a>
          </div>
          <div className="right">
            {/* <!--we can put contnet in the header--> */}
            {this.props.right ? this.props.right(this) : null}
            <Button
              icon="pi pi-ellipsis-v"
              onClick={(e: any): any => { this.rightmenu.toggle(e, e.target); }} aria-haspopup aria-controls="overlay_panel" />
            <OverlayPanel id="overlay_panel" className="header-menu" ref={(el: any): any => this.rightmenu = el}>
              <MegaMenu ref={(el: any): any => this.menu = el} model={this.menuItems} />
            </OverlayPanel>
          </div>
        </div >
        {(window.config && window.config['debug']) && this.renderDebugDialog()}

      </React.Fragment >
    );
  }
}

export default AppHeader;
